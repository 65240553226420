<template>
  <AuthWrapper>
    <p class="auth-notice">Нет аккаунта? <router-link to="/auth/register">Регистрация</router-link></p>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> Вход в <span class="color-secondary">панель проекта</span> </sdHeading>
        <a-form-item name="username" label="Ваш email">
          <a-input type="email" v-model:value="formState.email" />
        </a-form-item>
        <a-form-item name="password" initialValue="123456" label="Ваш пароль">
          <a-input type="password" v-model:value="formState.password" placeholder="Пароль" />
        </a-form-item>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">Запомнить меня</a-checkbox>
          <!-- <router-link class="forgot-pass-link" to="/auth/forgotPassword"> -->
          <router-link class="forgot-pass-link" to="/auth/login">
            Забыли пароль?
          </router-link>
        </div>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large" @click="login" :disabled="isLoading">
            {{ isLoading ? 'Загрузка...' : 'Войти' }}
          </sdButton>
        </a-form-item>
        <!-- <p class="form-divider">
          <span>Or</span>
        </p> -->
        <!-- <ul class="social-login">
          <li>
            <a class="google-signup" href="#">
              <img :src="require('@/static/img/google.png')" alt="" />
              <span>Sign in with Google</span>
            </a>
          </li>
          <li>
            <a class="facebook-sign" href="#">
              <FacebookOutlined />
            </a>
          </li>
          <li>
            <a class="twitter-sign" href="#">
              <TwitterOutlined />
            </a>
          </li>
        </ul> -->
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import axios from '@/config/axios.js'

const SignIn = {
  name: 'SignIn',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = /*computed(() => state.auth.loading)*/ref(false);
    const checked = ref(null);
    const router = useRouter();


    const login = async () => {
      isLoading.value = true;

      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios
          .post('/auth/login', {email: formState.email, password: formState.password})
          .then(response => {
            const {accessToken} = response.data

            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', accessToken)

            return response
          })
          .then(() => {
            dispatch('checkAuth').then(() => {
              router.push('/');
            });
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to login!')
            console.log('error :>> ', error.response)
            errorMessages.value = error.response.data.error
          })
          .finally(() => {
            console.log('123finished!');
            router.push('/');

          })
      });

      isLoading.value = false;
    };

    const handleSubmit = () => {
      // router.push('/');
      // dispatch('login');
    };

    const onChange = checked => {
      checked.value = checked;
    };

    const formState = reactive({
      email: 'hello@vibes.band',
      password: '12345678',
    });


    return {
      isLoading,
      checked,
      handleSubmit,
      onChange,
      formState,
      login
    };
  },
};

export default SignIn;
</script>
